<template>
  <BaseReportTemplate
    class="wrapper"
    :hide-logo="hideLogo"
    :headerLeftTitle="headerLeftTitle"
    :headerLeftSubTitle="headerLeftSubTitle"
    :headerRightTitle="headerRightTitle"
  >
    <div class="filter">
      <div class="list">
        <div v-for="(exam, index) in examList" :key="exam.ID_SINAVBILGI">
          <template v-if="index < 8">
            <input
              class="custombutton"
              type="checkbox"
              :id="'examCheckbox' + exam.ID_SINAVBILGI"
              :value="exam.ID_SINAVBILGI"
              v-model="examListCheck"
            />
            <label :for="'examCheckbox' + exam.ID_SINAVBILGI">
              {{ exam.KOD }}
            </label>
          </template>
        </div>
        <v-btn
          small
          :disabled="!examListCheck.length"
          @click="
            fetchExamResult();
            updateSelectedExams();
          "
          >Listele</v-btn
        >
      </div>
      <div class="info">
        <template v-if="treeData.length && examListCheck.length">
          <DoughnutChart
            :chart-data="generalSuccessDoughnutChartModel.chartData()"
            :styles="chart.style"
            :cutout-percentage="chart.percent"
            :innerFontSize="chart.fontSize"
            :innerFontStyle="'GothamCondensedBold'"
            :sliceFontSize="chart.sliceFontSize"
          ></DoughnutChart>
          <NetIcon
            class="mb-4 mt-3"
            variant="large"
            :student-net="
              isVariant.student ? generalSuccessDoughnutChartModel.studentNet : undefined
            "
            :corp-net="isVariant.corp ? generalSuccessDoughnutChartModel.studentNet : undefined"
            :class-net="isVariant.class ? generalSuccessDoughnutChartModel.studentNet : undefined"
            :average-net="generalSuccessDoughnutChartModel.averageNet"
          />
          <Label
            :style="label.out"
            size="medium"
            color="orange"
            :left="averagePointText"
            :left-style="label.left"
            left-position="center"
            :right-style="label.right"
            right-position="center"
            :right="score"
            :rightSidePercent="32"
          ></Label>
        </template>
        <template v-else>
          Sınavlara ait toplam net bilgileri ve kazanım ağacı için sol taraftan sınav seçin ve
          listele butonuna basin.
        </template>
        <div class="filter-type">
          <div class="filter-type-item" v-for="(filter, i) in filters" :key="i">
            <input
              class="custombutton"
              type="radio"
              name="listType"
              :value="Number(i) + 1"
              :id="'listTypeRadio' + i"
              v-model="listTypeRadio"
            />
            <label :for="'listTypeRadio' + i">
              {{ filter.text }}
            </label>
          </div>
        </div>
      </div>
      <div class="list">
        <div v-for="(exam, index) in examList" :key="exam.ID_SINAVBILGI">
          <template v-if="index > 7">
            <input
              class="custombutton"
              type="checkbox"
              :id="'examCheckbox' + exam.ID_SINAVBILGI"
              :value="exam.ID_SINAVBILGI"
              v-model="examListCheck"
            />
            <label :for="'examCheckbox' + exam.ID_SINAVBILGI">
              {{ exam.KOD }}
            </label>
          </template>
        </div>
      </div>
    </div>
    <v-expansion-panels multiple v-model="panel">
      <v-expansion-panel v-for="(lesson, i) of treeData" :key="i" :value="i">
        <div class="v-expansion-panel-head">
          <v-expansion-panel-header>
            <span>{{ lesson.lesson }}</span>
          </v-expansion-panel-header>
          <DoughnutChart
            :chart-data="getDoughnutDataFromTree(lesson)"
            :styles="headChart"
            :cutout-percentage="58"
            :innerFontSize="31"
            :sliceFontSize="13"
            :innerFontStyle="'GothamCondensedBook'"
          />
          <NetIcon
            :student-net="isVariant.student ? lesson.studentNet : undefined"
            :corp-net="isVariant.corp ? lesson.corpNet : undefined"
            :class-net="isVariant.class ? lesson.net : undefined"
            :average-net="lesson.averageNet"
            :iconStyles="{ height: '19px' }"
            :textStyles="{ fontSize: '15px', color: '#999999' }"
            :outerStyles="headIconStyle"
          />
        </div>
        <v-expansion-panel-content :id="'innerExPan' + i">
          <TreeTableChart
            :tree-data="form.examType == 3 ? lesson : lesson.subLessons"
            :listTypeRadio="listTypeRadio"
          />
          <GainsTreeDetail :treeData="treeData" :lessonTitle="lesson.lesson" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <Jumbotron class="w-100 mt-5 mb-0" style="min-height:55px" background>
      Kazanım ağacınızdaki açmış yeşil çiçekler, yaptıklarınızla doğru yolda olduğunuzu
      göstermektedir. Kuru kırmızı çiçekleri de yeşile çevirmek senin elinde. Konuları tekrar ederek
      ve yeterince soru çözerek, kazanım ağacını yemyeşil çiçeklerle donatabilirsin.
    </Jumbotron>
    <hr />
    <div class="footer-legend">
      <div class="footer-legend-left">
        <div><fa icon="plus-square" :style="{ color: this.theme.colors.GREEN }"></fa>ÜNİTE</div>
        <div>
          <fa icon="plus-square" :style="{ color: this.theme.colors.LIGHT_GREEN }"></fa>KONU
        </div>
        <div>
          <fa icon="angle-double-right" :style="{ color: this.theme.colors.GRAY }"></fa>KAZANIM
        </div>
      </div>
      <GainTreeLabel is-legend></GainTreeLabel>
    </div>
  </BaseReportTemplate>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import BaseReportTemplate from "@/components/reports/base-report.component.vue";
import Jumbotron from "@/components/reports/general/jumbotron.vue";
import GainTreeLabel from "@/components/reports/gains/gains-tree-label.vue";
import GainsTreeDetail from "@/components/reports/gains/gains-tree-detail.vue";
import DoughnutChart from "@/components/charts/doughnut.component.vue";
import TreeTableChart from "@/components/charts/tree-table.component.vue";
import NetIcon from "@/components/icons/net.component.vue";
import Label from "@/components/label.component.vue";
import doughnutLgsData from "@/common/mock/doughnut-lgs.json";
import ptToPxMixin from "@/components/mixins/pt-to-px.mixin";
import FormStoreMixin from "@/components/mixins/form.mixin";
import { REPORT_VARIANTS } from "@/common/config";
import { DoughnutChartModel } from "@/common/models";
import TytStudentService from "@/common/services/api-parsers/tyt/student.service";
import SbsCorpService from "@/common/services/api-parsers/sbs/corp.service";
import TytClassService from "@/common/services/api-parsers/tyt/class.service";
import { byDeviceWidth } from "@/common/utils";
import { SinavAPI } from "@/common/api";
import SbsClassService from "@/common/services/api-parsers/sbs/class.service";

export default {
  name: "tyt-gains-tree",
  mixins: [ptToPxMixin, FormStoreMixin],
  props: {
    variant: {
      type: String,
      default: "student",
      validator(value) {
        const variants = [REPORT_VARIANTS.STUDENT, REPORT_VARIANTS.CORP, REPORT_VARIANTS.CLASS];
        return variants.indexOf(value) !== -1;
      }
    }
  },
  components: {
    BaseReportTemplate,
    DoughnutChart,
    GainTreeLabel,
    Jumbotron,
    Label,
    NetIcon,
    TreeTableChart,
    GainsTreeDetail
  },
  async created() {
    await this.fetchExamList();
  },
  data() {
    return {
      score: 0,
      generalSuccessDoughnutChartModel: new DoughnutChartModel({}),
      treeData: [],
      doughnutLgsData,
      filters: [
        { text: "Ders", value: "lesson" },
        { text: "Ünite", value: "unit" },
        { text: "Konu", value: "topic" },
        { text: "Kazanım", value: "gain" }
      ],
      selectedFilter: null,
      examList: [],
      examListCheck: [],
      listTypeRadio: 1,
      panel: []
    };
  },
  watch: {
    listTypeRadio: function(val) {
      val > 1 ? (this.panel = Object.keys(this.treeData).map(Number)) : (this.panel = []);
    }
  },
  methods: {
    ...mapActions(["updateExamList"]),
    async fetchExamList() {
        let userData = this.$cookies.get("s");
      if (this.isVariant.student) {
          let sinavTur = this.$route.query.stur;
          this.form.student = userData.ID_KULLANICI;
          this.form.branch = userData.ID_SUBE;
          this.form.donem = userData.DONEM;
          this.form.examType = sinavTur;
          console.log(this.form);
          console.log(userData.DONEM);
          console.log(this.form.student);
          let data = await SinavAPI.OgrenciSinavTurSecimineGoreSinavlari(this.form.student,this.form.examType);
          data = data.map(exam => {
            return {
              ...exam,
              disabled: exam.DURUM === "girmedi"
            };
          });
          console.log(data)
        this.examList = data
          .filter(exam => !exam.disabled)
          .map(exam => {
            return {
              ...exam,
              active: false
            };
          });
      } else {
        this.examList = [...this.form.exams];
      }
    },
    async fetchExamResult() {
      const { student } = this.form;
      const generalSuccessData = {
        title: "",
        correct: 0,
        wrong: 0,
        empty: 0,
        correctnessPercent: 0,
        studentNet: 0,
        averageNet: 0
      };
      let score = 0;
      let treeData = [];

      if (this.form.examType == 3) {
        // 3 ise BDS
        if (this.isVariant.student) {
          const generalNets = await SinavAPI.totalNetsByExam({
            userId: student,
            examIdList: this.examListCheck
          });
          generalSuccessData.correct = generalNets.correct;
          generalSuccessData.wrong = generalNets.wrong;
          generalSuccessData.empty = generalNets.empty;
          generalSuccessData.correctnessPercent = generalNets.correctnessPercent;
          generalSuccessData.studentNet = generalNets.studentNet;
          generalSuccessData.averageNet = generalNets.averageNet;
          score = generalNets.studentScore;
          treeData = await SinavAPI.gainsTreeByExam({
            userId: student,
            examIdList: this.examListCheck
          });          
        } else if (this.isVariant.class) {
          const { general, treeData: tree } = await SbsClassService.gainsTree({
            examIdList: this.examListCheck,
            classId: this.form.class
          });          
          generalSuccessData.correct = general.correct;
          generalSuccessData.wrong = general.wrong;
          generalSuccessData.empty = general.empty;
          generalSuccessData.correctnessPercent = general.correctnessPercent;
          generalSuccessData.studentNet = general.corpAverageNet;
          generalSuccessData.averageNet = general.examAverageNet;
          score = general.averageScore;
          treeData = tree;
        } else {
          const { general, treeData: tree } = await SbsCorpService.gainsTree({
            examIdList: this.examListCheck,
            branchId: this.form.branch
          });
          generalSuccessData.correct = general.correct;
          generalSuccessData.wrong = general.wrong;
          generalSuccessData.empty = general.empty;
          generalSuccessData.correctnessPercent = general.correctnessPercent;
          generalSuccessData.studentNet = general.corpAverageNet;
          generalSuccessData.averageNet = general.examAverageNet;
          score = general.averageScore;
          treeData = tree;
        }
      } else {
        if (this.isVariant.student) {
          const { nets, score: avgScore, treeDataByMainLesson } = await TytStudentService.gainsTree(
            {
              userId: student,
              examIdList: this.examListCheck
            }
          );
          treeData = treeDataByMainLesson;
          score = avgScore;
          generalSuccessData.correct = nets.correct;
          generalSuccessData.wrong = nets.wrong;
          generalSuccessData.empty = nets.empty;
          generalSuccessData.correctnessPercent = nets.correctnessPercent;
          generalSuccessData.studentNet = nets.net;
          generalSuccessData.averageNet = nets.averageNet;
        } else if (this.isVariant.class) {
          const {
            nets: general,
            score: avgScore,
            treeDataByMainLesson
          } = await TytClassService.gainsTree({
            examIdList: this.examListCheck,
            classId: this.form.class
          });

          generalSuccessData.correct = general.correct;
          generalSuccessData.wrong = general.wrong;
          generalSuccessData.empty = general.empty;
          generalSuccessData.correctnessPercent = general.correctnessPercent;
          generalSuccessData.studentNet = general.net;
          generalSuccessData.averageNet = general.averageNet;

          treeData = treeDataByMainLesson;
          score = avgScore;
        } else {
          const { general, treeData: tree } = await SbsCorpService.gainsTree({
            examIdList: this.examListCheck,
            branchId: this.form.branch
          });
          generalSuccessData.correct = general.correct;
          generalSuccessData.wrong = general.wrong;
          generalSuccessData.empty = general.empty;
          generalSuccessData.correctnessPercent = general.correctnessPercent;
          generalSuccessData.studentNet = general.corpAverageNet;
          generalSuccessData.averageNet = general.examAverageNet;
          score = general.averageScore;
          treeData = tree;
        }
      }
      this.generalSuccessDoughnutChartModel = new DoughnutChartModel(generalSuccessData);
      this.treeData = treeData;
      this.score = score;

      this.listTypeRadio > 1 ? (this.panel = Object.keys(treeData).map(Number)) : (this.panel = []);
    },
    getDoughnutDataFromTree(data) {
      return new DoughnutChartModel({ ...data, title: "" }).chartData();
    },
    updateSelectedExams() {
      const list = this.examListCheck.map(id => {
        return this.examList.find(exam => exam.ID_SINAVBILGI == id);
      });
      this.updateExamList(list);
    }
  },
  computed: {
    ...mapGetters(["theme"]),
    isVariant() {
      return {
        student: this.variant === REPORT_VARIANTS.STUDENT,
        corp: this.variant === REPORT_VARIANTS.CORP,
        class: this.variant === REPORT_VARIANTS.CLASS
      };
    },
    headerLeftTitle() {
      let name = "Kazanım Ağacı";
      if (this.variant === REPORT_VARIANTS.CORP || this.variant === REPORT_VARIANTS.CLASS) {
        name = this.form.selected.branch.AD;
      }
      return name;
    },
    headerLeftSubTitle() {
      if (this.variant === REPORT_VARIANTS.STUDENT) {
        return null;
      } else if (this.variant === REPORT_VARIANTS.CORP) {
        return this.form.selected.class.AD;
      } else {
        return `${this.form.selected.class.AD} / ${this.form.selected.class.DERSLIK}`;
      }
    },
    headerRightTitle() {
      if (this.variant === REPORT_VARIANTS.CORP || this.variant === REPORT_VARIANTS.CLASS) {
        return "Kazanım Ağacı";
      }
      return null;
    },
    hideLogo() {
      return this.variant === REPORT_VARIANTS.STUDENT;
    },
    averagePointText() {
      let text = "Puan Ortalaması";
      if (this.variant === REPORT_VARIANTS.STUDENT) {
        text = "Puan Ortalamam";
      }
      return text;
    },
    chart() {
      return {
        style: byDeviceWidth({
          desktop: { height: "290px", width: "290px", position: "relative" },
          mobile: { height: "213px", width: "213px", position: "relative" }
        }),
        percent: byDeviceWidth({
          desktop: 60,
          mobile: 60
        }),
        fontSize: byDeviceWidth({
          desktop: 106,
          mobile: 78
        }),
        sliceFontSize: byDeviceWidth({
          desktop: 28,
          mobile: 20
        })
      };
    },
    label() {
      const common = {
        fontFamily: "GothamNarrowBlack",
        ...byDeviceWidth({
          desktop: {
            fontSize: "19px"
          },
          mobile: {
            fontSize: "14px"
          }
        })
      };
      return {
        out: byDeviceWidth({
          desktop: {
            height: "38px",
            width: "282px",
            marginBottom: "35px"
          },
          mobile: {
            height: "29px",
            width: "222px",
            marginBottom: "28px"
          }
        }),
        left: {
          width: "69%",
          ...common
        },
        right: {
          width: "31%",
          ...common
        }
      };
    },
    headIconStyle() {
      return byDeviceWidth({
        desktop: {
          marginLeft: "0",
          transform: "translateY(0)"
        },
        mobile: {
          marginLeft: "40px",
          transform: "translateY(-20px)"
        }
      });
    },
    headChart() {
      return byDeviceWidth({
        desktop: { height: "111px", width: "151px", position: "relative" },
        mobile: { height: "90px", width: "100px" }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 640px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.filter {
  display: flex;
  margin-bottom: 25px;
  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  text-align: center;
  .info {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
    @media (min-width: 768px) {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  .list {
    button.v-btn {
      background-color: #8cc63e !important;
      color: white;
      font-family: "GothamNarrowBlack";
      font-size: 8px;
      padding: 4px;
      border-radius: 2.9px;
      min-width: 44px;
      @media (min-width: 768px) {
        font-size: 13px;
      }
    }
  }
  &-type {
    flex: 1;
    margin-top: auto;
    display: flex;
    justify-content: center;
    &-item {
      margin-left: 7.5px;
      margin-right: 7.5px;
    }
  }
}
.custombutton {
  appearance: none;
  &:checked + label {
    background-color: rgb(245, 130, 31);
    color: white;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.07), 0px 2px 2px 0px rgba(0, 0, 0, 0.06),
      0px 1px 5px 0px rgba(0, 0, 0, 0.05);
  }
  & + label {
    color: #666666;
    background-color: #e5e5e5;
    text-transform: uppercase;
    font-family: "GothamCondensedBold";
    font-size: 11px;
    padding: 4px;
    border-radius: 2.9px;
    min-width: 44px;
    text-align: center;
    cursor: pointer;
    @media (min-width: 768px) {
      min-width: 66px;
      font-size: 13.52px;
    }
  }
}
.v-expansion-panel {
  &::before {
    box-shadow: none;
  }
  &::after {
    border: none !important;
  }
  &-head {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    border-top: 1px solid rgba(102, 102, 102, 0.25);
    padding-top: 7px;
    @media (min-width: 768px) {
      flex-wrap: nowrap;
      margin-left: 40px;
      margin-right: 40px;
    }
    @media (min-width: 992px) {
      padding-top: 17px;
      padding-bottom: 17px;
    }
  }
  &-header {
    position: static;
    flex: 1 1 195px;
    max-width: 195px;
    background-color: #999999;
    align-self: center;
    color: white;
    border-radius: 6px !important;
    border: 1px solid rgb(218, 218, 218);
    margin-right: auto;
    padding: 7px 10px;
    font-family: "GothamNarrowBlack";
    font-size: 17px;
    text-transform: uppercase;
    min-height: 0;
    @media (min-width: 768px) {
      font-size: 18px;
      padding: 10px 20px;
      margin-right: 20px;
      flex: 1 1 304px;
      max-width: 304px;
    }
    span {
      z-index: 1;
      text-align: left;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -5px;
      right: -5px;
      bottom: 0;
      background-color: rgba(102, 102, 102, 0.05);
      opacity: 0;
      @media (min-width: 768px) {
        left: -40px;
        right: -40px;
      }
    }
    &::after {
      z-index: 1;
      content: "";
      margin-left: auto;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid white;
    }
    &--active {
      &::after {
        border-top: 0;
        border-bottom: 8px solid white;
      }
      &::before {
        opacity: 1;
      }
    }
  }
}
.footer-legend {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 14px;
  @media (min-width: 768px) {
    flex-wrap: nowrap;
  }
  &-left {
    display: flex;
    margin-right: auto;
    font-size: 10.62px;
    font-family: "GothamCondensedBook";
    margin-bottom: 12px;
    @media (min-width: 768px) {
      margin-bottom: 0;
    }
    div {
      display: flex;
      align-items: center;
      line-height: 1;
      margin-right: 10px;
    }
    svg {
      margin-right: 4px;
      font-size: 15.5px;
      transform: translateY(-1px);
    }
  }
}
</style>
