<template>
      <GainsTreeReport></GainsTreeReport>      
</template>

<script>
import GainsTreeReport from "@/views/pages/da/gains-tree.vue";
export default { 
  name: "gains-tree",
  components: {
    GainsTreeReport
  }
};
 </script>