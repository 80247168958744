 
<template>
    <LGSContainer key="1234"></LGSContainer>
</template>
 <script>
import LGSContainer from "@/views/pages/da/lgs.vue";

export default { 
  name: "test",
  components: {
    LGSContainer
  }
};
 </script>
 